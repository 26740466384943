<template>
    <div class="dashboard-maintenance-history-view">
        <div class="dashboard-maintenance-history-view__filters">
            <BaseMultiselect
                v-model="filterPolicy"
                :placeholder="$t('maintenancePolicies')"
                :options="maintenancePolicies"
                track-by="id"
                label="name"
                multiple
                block
            />

            <BaseMultiselect
                v-model="filterAsset"
                :placeholder="$t('shared.assets')"
                :options="trackers"
                track-by="id"
                :custom-label="option => option.asset_details.name"
                multiple
                block
            />

            <BaseButton block @click="openCreateModal">
                <template #icon>
                    <AddIcon width="16" height="16" />
                </template>

                {{ $t('create') }}
            </BaseButton>
        </div>

        <div class="dashboard-maintenance-history-view__content">
            <MaintenanceHistoryTable
                ref="maintenanceHistoryTable"
                :filter-asset="filterAsset"
                :filter-policy="filterPolicy"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import AddIcon from '../icons/AddIcon'
import BaseButton from '../redesigned/BaseButton'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import MaintenanceFormModal from '../redesigned/MaintenanceFormModal'
import MaintenanceHistoryTable from '../MaintenanceHistoryTable'

export default {
    name: 'DashboardMaintenanceHistoryView',
    components: {
        AddIcon,
        BaseButton,
        BaseMultiselect,
        MaintenanceHistoryTable,
    },
    data() {
        return {
            filterAsset: [],
            filterPolicy: [],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapState('maintenance', ['maintenancePolicies']),
    },
    watch: {
        filterAsset() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    asset: this.filterAsset.map(item => item.asset),
                },
            })
        },
        filterPolicy() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    policy: this.filterPolicy.map(item => item.id),
                },
            })
        },
    },
    mounted() {
        if (this.$route.query.asset) {
            const ids = [].concat(this.$route.query.asset).map(Number)
            this.filterAsset = this.trackers.filter(item =>
                ids.includes(item.asset)
            )
        }

        if (this.$route.query.policy) {
            const ids = [].concat(this.$route.query.policy).map(Number)
            this.filterPolicy = this.maintenancePolicies.filter(item =>
                ids.includes(item.id)
            )
        }
    },
    methods: {
        openCreateModal() {
            this.$modal.show(
                MaintenanceFormModal,
                {
                    onSubmit: this.$refs.maintenanceHistoryTable
                        .fetchMaintenanceHistory,
                },
                { width: 600, height: '75%' }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "create": "Add a new record",
        "maintenancePolicies": "Maintenance policies"
    },
    "de": {
        "create": "Neuen Wartungssatz hinzufügen",
        "maintenancePolicies": "Wartungspläne"
    },
    "fr": {
        "create": "Ajouter un nouvel de maintenance",
        "maintenancePolicies": "Politiques de maintenance"
    },
    "it": {
        "create": "Aggiungere un nuovo record",
        "maintenancePolicies": "Politiche di manutenzione"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-maintenance-history-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }
    }

    &__content {
        overflow-y: auto;
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
